import { LeadDTO, User, Vehicle, VehicleFilter, VehicleGetDTO, VehicleSort } from "@alba-cars/common-modules";
import { InfiniteData } from "@tanstack/react-query";
import { clsx, type ClassValue } from "clsx";
import { redirect } from "next/navigation";
import { twMerge } from "tailwind-merge";

import { FilterArrayField, FilterRangeFields, OmittedVehicleGetDTO } from "@/app/buy-used-cars-uae/_components/types";
import { footerMakeMap } from "@/utils/constants";
import { getMakeBySlug } from "./api/get-makes";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function convertTo24Hour(time: string): string {
  // Extract hours, minutes, and period
  const [timeStr, period] = time.split(" ");
  let [hours, minutes] = timeStr.split(":").map(Number);

  // Convert to 24-hour format
  if (period.toLowerCase() === "pm" && hours !== 12) {
    hours += 12;
  } else if (period.toLowerCase() === "am" && hours === 12) {
    hours = 0;
  }

  // Format the output with leading zeros
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

/**
 * Validates if a string is a valid URL
 */
function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
}

/**
 * Formats an image URL by ensuring it has the correct domain and leading slash
 * @param src - The source URL or path
 * @param config - Optional configuration object
 * @returns The properly formatted URL
 * @throws Error if src is undefined or null
 */
export function formatImageUrl(src: string | null | undefined): string | null {
  if (!src) {
    return null;
  }

  // Return as-is if it's already an absolute URL
  if (isValidUrl(src)) {
    return src;
  }

  // Return as-is if it's a data URL
  if (src.startsWith("data:")) {
    return src;
  }
  if (src.startsWith("/assets")) {
    return src;
  }
  const domain = process.env.NEXT_PUBLIC_S3_URL;

  // Ensure src has a leading slash
  const normalizedSrc = src.startsWith("/") ? src : `/${src}`;

  // Combine domain with normalized path
  return `${domain}${normalizedSrc}`;
}

export const constructInfiniteQueryData = <T>(data: T, page?: number): InfiniteData<T> => {
  const result: InfiniteData<T> = {
    pages: [data],
    pageParams: [page ?? 0],
  };

  return result;
};

export const baseQuery: OmittedVehicleGetDTO = {
  options: {
    page: 1,
    limit: 20,
    withMake: true,
    withModel: true,
    withBodyType: true,
    // withFeatures: true,
    withFinance: true,
    select: [
      "id",
      "refId",
      "title",
      "vin",
      "status",
      "chassisNumber",
      "trim",
      "color",
      "airbags",
      "doors",
      "price",
      "year",
      "fuelType",
      "engineCapacity",
      "mileage",
      "createdAt",
      "updatedAt",
      "make.id",
      "make.name",
      "model.id",
      "model.name",
      "bodyType.id",
      "bodyType.name",
      // "features.id",
      // "features.name",
    ],
  },
};

export const ARRAY_FIELDS: FilterArrayField[] = ["make", "model", "bodyType", "fuelType", "features"];
export const RANGE_FIELDS: FilterRangeFields[] = ["mileage", "year", "price", "emi"];

export const genQuery = async (
  searchParams: Record<string, any>,
): Promise<Omit<VehicleGetDTO, "toPlain" | "validate">> => {
  // Check for filter parameters
  const hasFilterParams = Object.keys(searchParams).some(
    (key) =>
      key.endsWith("Min") || key.endsWith("Max") || ARRAY_FIELDS.includes(key as FilterArrayField) || key === "makes",
  );

  // Initialize the query with base query
  const query = { ...baseQuery };

  // Handle filters if present
  if (hasFilterParams) {
    const initialFilter = new VehicleFilter();

    const getParam = (key: string) => {
      if (searchParams instanceof URLSearchParams) {
        return searchParams.get(key);
      }
      return searchParams[key];
    };

    // Handle array fields
    ARRAY_FIELDS.forEach((field) => {
      if (field === "odometer") {
        return;
      }
      if (searchParams[field]) {
        const value = getParam(field);
        initialFilter[field] = Array.isArray(value) ? value : [value];
      }
    });

    const minPrice = getParam("minPrice") || getParam("minprice");
    const maxPrice = getParam("maxPrice") || getParam("maxprice");

    // Set price range if either parameter is present
    if (minPrice || maxPrice) {
      initialFilter.price = {
        min: minPrice ? Number(minPrice) : undefined,
        max: maxPrice ? Number(maxPrice) : undefined,
      };
    }

    const odometer = getParam("odometer");

    if (odometer) {
      initialFilter.mileage = {
        min: odometer ? Number(odometer) : undefined,
        max: odometer ? Number(odometer) : undefined,
      };
    }

    // Handle range fields
    RANGE_FIELDS.forEach((field) => {
      if (field === "price" && initialFilter.price) {
        return;
      }

      if (field === "mileage" && initialFilter.mileage) {
        return;
      }

      const min = getParam(`${field}Min`);
      const max = getParam(`${field}Max`);
      if (min || max) {
        initialFilter[field] = {
          min: min ? Number(min) : undefined,
          max: max ? Number(max) : undefined,
        };
      }
    });

    if (getParam("makes")) {
      const makeSlug = footerMakeMap[getParam("makes") as keyof typeof footerMakeMap];
      if (makeSlug) {
        redirect(`/search?make=${makeSlug}`);
      } else {
        redirect(`/search`);
      }
    }

    if (Object.keys(initialFilter).length > 0) {
      query.filter = initialFilter;
    }
  }

  const sort: Record<string, "asc" | "desc" | Record<string, "asc" | "desc">> = {};
  Array.from(Object.entries(searchParams)).forEach(([key, value]) => {
    if (key.startsWith("sort[")) {
      const matches = key.match(/sort\[(.*?)\](?:\[(.*?)\])?/);
      if (matches) {
        const [, mainKey, nestedKey] = matches;

        if (nestedKey) {
          // Handle nested sort
          if (!sort[mainKey] || typeof sort[mainKey] === "string") {
            sort[mainKey] = {};
          }
          (sort[mainKey] as Record<string, "asc" | "desc">)[nestedKey] = value as "asc" | "desc";
        } else {
          // Handle simple sort
          sort[mainKey] = value as "asc" | "desc";
        }
      }
    }
  });
  // Only set options if there are any

  query.options = {
    ...baseQuery.options,
    sort: sort as VehicleSort | undefined,
  };

  return query;
};

// Simulate a delayed response
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

// Car favorites
export const FAVORITES_KEY = "car-favorites";

export const getLocalFavorites = (): Set<string> => {
  try {
    const stored = localStorage.getItem(FAVORITES_KEY);
    return new Set(stored ? JSON.parse(stored) : []);
  } catch {
    return new Set();
  }
};

export const saveLocalFavorites = (favorites: Set<string>): void => {
  localStorage.setItem(FAVORITES_KEY, JSON.stringify(Array.from(favorites)));
};

export interface Deposit {
  id: string;
  stripePaymentIntentId: string;
  amount: number;
  currency: string;
  status: "pending" | "completed" | "failed";
  isRefundable: boolean;
  isRefunded: boolean;
  lead?: LeadDTO;
  user?: User;
  vehicle: Vehicle;
  isDeleted?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export const getFileSizeInfo = (size: number) => {
  return {
    bytes: size,
    kilobytes: size / 1024,
    megabytes: size / 1024 / 1024,
    gigabytes: size / 1024 / 1024 / 1024,
  };
};

// parses and returns the refId from the slug in the format refId-make-model
export const getVehicleRefFromSlug = (slugString: string) => {
  const [refId, _make, _model, _trim] = slugString.split("-");
  return refId;
};

export function slugify(text: string): string {
  if (!text || typeof text !== "string") return "";

  // Convert to lowercase and trim whitespace
  let slug = text.toLowerCase().trim();

  // Replace multiple spaces/dashes/underscores with single dash
  slug = slug.replace(/[\s_-]+/g, "-");

  // Remove any characters that aren't alphanumeric or dashes
  slug = slug.replace(/[^a-zA-Z0-9-]/g, "");

  // Remove dashes from start and end
  slug = slug.replace(/^-+|-+$/g, "");

  return slug;
}

// A utility function to split the array into rows (chunks) of given size.
export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const MAX_SAFE_INTEGER = 2147483647;

/**
 * Removes the trailing slash from a URL if it exists
 */
export const removeUrlTrailingSlash = (url: string): string => {
  if (!url) return "";
  return url.endsWith("/") ? url.slice(0, -1) : url;
};

export const siteUrl = removeUrlTrailingSlash((process.env.NEXT_PUBLIC_APP_URL || "") as string);

export const getCarPrice = (car?: Vehicle): number => {
  if (!car) return 0;

  if (!car.finance) return 0;

  if (car.finance.vehiclePriceType === "dropped_price" && car.finance.droppedPrice) {
    return car.finance.droppedPrice;
  }

  if (car.finance.price) {
    return car.finance.price;
  }

  return 0;
};

export const getCarMonthlyInstallment = (car?: Vehicle): number => {
  if (!car) return 0;

  if (!car.finance) return 0;

  if (car.finance.vehiclePriceType === "dropped_price") {
    return car.finance.droppedMonthlyInstallment || car.finance.monthlyInstallment || 0;
  }

  if (car.finance.monthlyInstallment) {
    return car.finance.monthlyInstallment;
  }

  return 0;
};

export const roundNumberTo = (value: number, digits: number = 2) => {
  const ROUND = Math.pow(10, digits);
  return Math.round(value * ROUND) / ROUND;
};
